// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: "Ubuntu", sans-serif;
$font-family-heading: "Roboto", sans-serif;
$font-family-light: "Roboto", sans-serif;
$font-family-base: "Ubuntu", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// LAYOUT VARS
$topbar-height: 80px;
$topbar-height-mobile: 70px;

$sidebar-left-width: 120px;
$sidebar-left-width-mobile: 90px;
$sidebar-left-secondary-width: 220px;
$sidebar-left-secondary-width-mobile: 190px;
$side-clearance: 20px;

$white: #ffffff !default;
$gray-100: #f0f0f0 !default;
$gray-200: #efefef !default;
$gray-300: #dddddd !default;
$gray-400: #cccccc !default;
$gray-500: #aaaaaa !default;
$gray-600: #666666 !default;
$gray-700: #444444 !default;
$gray-800: #333333 !default;
$gray-900: #222222 !default;
$black: #000000 !default;

// Colors
$lity-blue: #80d3fd;
$light-blue: #008BD2;
$blue: #27348B;
$dark-blue: #0A154C;
$deep-blue: #17202a;
$indigo: #7572dd;
$purple: #9561e2;
$pink: #e66fc8;
$red: #cc0000;
$dark-red: #660000;
$orange: #E84910;
$yellow: #fdd800;
$green: #52AE32;
$teal: #6BC2B9;
$cyan: #2f98ea;

$primary: $blue !default;
$secondary: $dark-blue !default;
$tertiary: $light-blue !default;
$quaternary: $lity-blue !default;
$quinary: $teal !default;
$house-fiepb: $light-blue;
$house-sesi: $green;
$house-senai: $orange;
$house-iel: $teal;
$success: $green !default;
$info: $teal !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$ashen: $gray-300 !default;
$silver: $gray-500 !default;
$graphite: $gray-700 !default;
$deep: $gray-800 !default;
$dark: $deep-blue !default;

// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);
$instagram: rgb(193, 53, 132);
$linkedin: rgb(0, 119, 181);
$dribble: #ea4c89;
$youtube: #c4302b;
$whatsapp: #2fb140;

:root {
  --bs-blue: $blue;
  --bs-indigo: $indigo;
  --bs-purple: $purple;
  --bs-pink: $pink;
  --bs-red: $red;
  --bs-orange: $orange;
  --bs-yellow: $yellow;
  --bs-green: $green;
  --bs-teal: $teal;
  --bs-cyan: $cyan;
  --bs-white: $white;
  --bs-gray: $gray;
  --bs-gray-dark: $gray-dark;
  --bs-primary: $primary;
  --bs-secondary: $secondary;
  --bs-tertiary: $tertiary;
  --bs-quaternary: $quaternary;
  --bs-quinary: $quinary;
  --bs-success: $success;
  --bs-info: $info;
  --bs-warning: $warning;
  --bs-danger: $danger;
  --bs-light: $light;
  --bs-dark: $dark;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "quaternary": $quaternary,
  "quinary": $quinary,
  "house-fiepb": $house-fiepb,
  "house-sesi": $house-sesi,
  "house-senai": $house-senai,
  "house-iel": $house-iel,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "dark": $dark,
  "light": $light,
  "ashen": $ashen,
  "silver": $silver,
  "graphite": $graphite,
  "deep": $deep,
  "black": $black,
  "white": $white,
  "facebook": $facebook,
  "google": $google,
  "twitter": $twitter,
  "instagram": $instagram,
  "linkedin": $linkedin,
  "dribble": $dribble,
  "youtube": $youtube,
  "whatsapp": $whatsapp,
) !default;

$fsizes: (
  "1": 1.75rem,
  "2": 1.5rem,
  "3": 1.25rem,
  "4": 1rem,
  "5": 0.9rem,
  "6": 0.8rem,
  "7": 0.7rem,
  "8": 0.6rem,
);

@each $fs, $value in $fsizes {
  .size-#{$fs} {
    font-size: $value !important;
  }
}

$zindex: (
  "1": 1,
  "10": 10,
  "20": 20,
  "30": 30,
  "40": 40,
  "50": 50,
);

@each $z, $value in $zindex {
  .zindex-#{$z} {
    z-index: $value !important;
  }
}

$bwidth: (
  "1": 1px,
  "2": 2px,
  "3": 3px,
  "4": 4px,
  "5": 5px,
);

@each $bw, $value in $bwidth {
  .border-#{$bw} {
    border-width: $value !important;
    border-style: solid !important;
  }

  .border-t-#{$bw} {
    border: 0px none !important;
    border-top-width: $value !important;
    border-top-style: solid !important;
  }

  .border-l-#{$bw} {
    border: 0px none !important;
    border-left-width: $value !important;
    border-left-style: solid !important;
  }

  .border-r-#{$bw} {
    border: 0px none !important;
    border-right-width: $value !important;
    border-right-style: solid !important;
  }

  .border-b-#{$bw} {
    border: 0px none !important;
    border-bottom-width: $value !important;
    border-bottom-style: solid !important;
  }
}

@each $name, $value in $theme-colors {
  .border-#{$name} {
    border-color: $value;
  }

  svg.logo.fill-#{$name} {
    .cls-1,
    .cls-2,
    .cls-3 {
      fill: $value;
    }
  }

  a.#{$name},
  a.link-#{$name} {
    color: $value;
  }

  .text-#{$name} {
    a {
      color: $value;
    }
  }

  .text-#{$name} {
    a:hover {
      color: lighten($value, 20%);
    }
  }

  a.#{$name}:hover,
  a.link-#{$name}:hover {
    color: lighten($value, 20%);
  }

  a.link-h-#{$name}:hover,
  a.link-h-#{$name}:hover * {
    color: $value !important;
  }

  .bg-transparent-#{$name} {
    background-color: unquote($value + "99");
  }
}

a.text-white:hover {
  color: $tertiary;
}

a.text-tertiary:hover {
  color: $white;
}

a.text-primary:hover {
  color: $secondary;
}

a.text-secondary:hover {
  color: $primary;
}

$bradius: (
  "0": 0rem,
  "1": 0.25rem,
  "2": 0.5rem,
  "3": 0.75rem,
  "4": 1rem,
  "5": 2rem,
);

@each $bw, $value in $bradius {
  .corner-#{$bw} {
    border-radius: $value !important;
  }
  .corner-ts-#{$bw} {
    border-top-left-radius: $value !important;
  }
  .corner-te-#{$bw} {
    border-top-right-radius: $value !important;
  }
  .corner-bs-#{$bw} {
    border-bottom-left-radius: $value !important;
  }
  .corner-be-#{$bw} {
    border-bottom-right-radius: $value !important;
  }

  .corner-t-#{$bw} {
    border-top-left-radius: $value !important;
    border-top-right-radius: $value !important;
  }
  .corner-e-#{$bw} {
    border-top-right-radius: $value !important;
    border-bottom-right-radius: $value !important;
  }
  .corner-b-#{$bw} {
    border-bottom-left-radius: $value !important;
    border-bottom-right-radius: $value !important;
  }
  .corner-s-#{$bw} {
    border-top-left-radius: $value !important;
    border-bottom-left-radius: $value !important;
  }
}

//CKEdior
figure.image {
  img {
    width: 100%;
  }

  figcaption {
    padding: 5px;
    background-color: $gray-200;
    font-size: 11px;
    color: #999;
  }
}

.image-style-align-right {
  float: right;
  margin: 5px 0px 5px 15px;

  figcaption {
    text-align: right;
  }
}

.image-style-align-left {
  float: left;
  margin: 5px 15px 5px 0px;

  figcaption {
    text-align: left;
  }
}

.image-style-align-center {
  margin: 15px auto;
}

article.posts {
  color: $gray-600;

  h1 {
    margin-top: 30px;
    font-size: 1.5rem;
    color: $secondary;
  }

  h2 {
    margin-top: 30px;
    font-size: 1.3rem;
    color: $primary;
  }

  h3 {
    margin-top: 30px;
    font-size: 1.2rem;
    color: $gray-800;
  }

  h4 {
    font-size: 1.1rem;
  }

  h5 {
    font-size: 1rem;
  }

  hr {
    margin: 30px 0px;
  }
}

.play.fas,
.play.far {
  opacity: 70%;
  font-size: 57px;
  text-shadow: 2px 2px #000;
  border-width: 6px;
  border-style: solid;
  padding: 0px 22px;
  border-radius: 300px;
  background-color: #000;
  width: 70px;
  height: 70px;
}

a.hover-img {
  img {
    transition: all 0.3s ease-in-out;
    width: 100%;
  }
  img:hover {
    filter: grayscale(100%);
  }
}