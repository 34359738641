// Fonts
@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.min.css";

// Variables
@import "variables";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// jQuery UI
@import "~jquery-ui/themes/base/all.css";
// Data Ranger Picker
@import "~daterangepicker/daterangepicker.css";
// Datetimepicker
@import "~jquery-datetimepicker/build/jquery.datetimepicker.min.css";
//Lity lightbox
@import "~lity/dist/lity.css";
// Datatables
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
a {
    text-decoration: none;
}

$defColorName: "primary", "secondary", "success", "info", "warning", "danger", "light", "dark", "silver";
$defColor: $primary, $secondary, $success, $info, $warning, $danger, $light, $dark, $silver;
$custom-file-text: (
    en: "Browse",
    pt: "Procurar",
);
$shadow: 2px 2px 2px #{$primary + "99"};
a:hover {
    text-decoration: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-heading;
    font-weight: 900;
    letter-spacing: 0.05em;
}

.h1,
h1 {
    font-size: 2.25rem;
}

.h2,
h2 {
    font-size: 1.8rem;
}

.h3,
h3 {
    font-size: 1.575rem;
}

.h4,
h4 {
    font-size: 1.35rem;
}

.h5,
h5 {
    font-size: 1.125rem;
}

.h6,
h6 {
    font-size: 1rem;
}

.h1.font-weight-light,
.h2.font-weight-light,
.h3.font-weight-light,
.h4.font-weight-light,
.h5.font-weight-light,
.h6.font-weight-light,
h1.font-weight-light,
h2.font-weight-light,
h3.font-weight-light,
h4.font-weight-light,
h5.font-weight-light,
h6.font-weight-light {
    font-family: $font-family-light;
    font-weight: normal;
}

.fas,
.far {
    font-family: "Font Awesome 5 Free";
}

.fab {
    font-family: "Font Awesome 5 Brands";
}

.h1.fa,
.h1.fas,
.h1.far,
.h2.fa,
.h2.fas,
.h2.far,
.h3.fa,
.h3.fas,
.h3.far,
.h4.fa,
.h4.fas,
.h4.far,
.h5.fa,
.h5.fas,
.h5.far,
.h6.fa,
.h6.fas,
.h6.far {
    font-weight: 900;
}

body {
    background-image: url("/images/back.png");
    background-size: 100% auto;
    background-repeat: repeat-x;
    line-height: 1.4;
}

@include media-breakpoint-down(sm) {
    body {
        background-size: 260% auto;
    }
}

#header {
    @include media-breakpoint-down(sm) {
        #contatos {
            #sociais,
            #telefones {
                h4 {
                    font-size: 1.15rem;
                    text-align: center;
                }
            }
        }
    }
}

.footer {
    .footer-bottom {
        background-image: url("/images/back-rodape.jpg");
        background-size: auto 100%;
        background-repeat: repeat-x;
    }
}

nav {
    font-size: 14px;
}

.bg-none {
    background-transparent: transparent;
    background-image: none;
}

.text-small,
.text-small a {
    font-size: 0.8em;
}

.text-editor {
    p,
    ol,
    ul,
    pre,
    blockquote,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding-bottom: 16px;
    }
    h1 {
        padding-bottom: 16px;
    }
}

blockquote {
    font-size: 1.3rem;
    border-left: 3px solid $silver;
    padding-left: 30px;
}

@include media-breakpoint-up(xs) {
    .h-auto {
        height: auto;
    }
}

$wdouble: 4px;
.border-0 {
    border: 0px none;
}

.border-2 {
    border-width: $wdouble !important;
}

.card {
    &.border-2 {
        border-width: $wdouble !important;
        border-style: solid !important;
        @for $i from 0 to length($defColorName) {
            &.border-#{nth($defColorName, $i+1)} {
                border-color: nth($defColor, $i + 1) !important;
            }
        }
    }
    &.corner-2 {
        .card-header {
            border-radius: 1.2rem 1.2rem 0rem 0rem;
        }
        .card-body {
            border-radius: 0rem;
        }
        .card-footer {
            border-radius: 0rem 0rem 1.2rem 1.2rem;
        }
    }
    &.bg-transparent {
        @for $i from 0 to length($defColorName) {
            &.border-#{nth($defColorName, $i+1)} {
                &:hover {
                    background-color: #{nth($defColor, $i + 1)}60 !important;
                }
            }
        }
    }
}

.autocomplete-items {
    border: 1px solid $gray-400;
    padding: 2px 5px;
    cursor: pointer;
    div:hover {
        background-color: $gray-200;
    }
}

.box-shadow {
    box-shadow: $shadow;
}

.box-shadow-none {
    box-shadow: none;
}

.text-shadow {
    text-shadow: $shadow;
}

tfoot {
    font-weight: bold;
}

.breadcrumb {
    font-weight: 900;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: "\F105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: $primary;
}

.filter-blur {
    filter: blur(3px);
}

/** Table Padrão **/

figure.table {
    table {
        --#{$variable-prefix}table-bg: #{$table-bg};
        --#{$variable-prefix}table-accent-bg: #{$table-bg};
        --#{$variable-prefix}table-striped-color: #{$table-striped-color};
        --#{$variable-prefix}table-striped-bg: #{$table-striped-bg};
        --#{$variable-prefix}table-active-color: #{$table-active-color};
        --#{$variable-prefix}table-active-bg: #{$table-active-bg};
        --#{$variable-prefix}table-hover-color: #{$table-hover-color};
        --#{$variable-prefix}table-hover-bg: #{$table-hover-bg};
        width: 100%;
        margin-bottom: $spacer;
        color: $table-color;
        vertical-align: $table-cell-vertical-align;
        border-color: $table-border-color;
        // Target th & td
        // We need the child combinator to prevent styles leaking to nested tables which doesn't have a `.table` class.
        // We use the universal selectors here to simplify the selector (else we would need 6 different selectors).
        // Another advantage is that this generates less code and makes the selector less specific making it easier to override.
        // stylelint-disable-next-line selector-max-universal
        > :not(caption) > * > * {
            padding: $table-cell-padding-y $table-cell-padding-x;
            background-color: var(--#{$variable-prefix}table-bg);
            border-bottom-width: $table-border-width;
            box-shadow: inset 0 0 0 9999px var(--#{$variable-prefix}table-accent-bg);
        }
        > tbody {
            vertical-align: inherit;
        }
        > thead {
            vertical-align: bottom;
        }
        // Highlight border color between thead, tbody and tfoot.
        > :not(:last-child) > :last-child > * {
            border-bottom-color: $table-group-separator-color;
        }
        > tbody > tr:nth-of-type(#{$table-striped-order}) {
            --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-striped-bg);
            color: var(--#{$variable-prefix}table-striped-color);
        }
        // stylelint-disable-next-line selector-max-universal
        > :not(caption) > * > * {
            border-bottom-width: 1;
            border-color: #bbb;
        }
        @each $breakpoint in map-keys($grid-breakpoints) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
            @include media-breakpoint-down($breakpoint) {
                .table-responsive#{$infix} {
                    overflow-x: auto;
                    -webkit-overflow-scrolling: touch;
                }
            }
        }
    }
}

.lightbox {
    .modal-dialog {
        max-width: none;

        .modal-body {
            .carousel-inner {
                .ratio {
                    background-color: transparent !important;
                }
            }
        }
    }
}

.masonry {
    /* clear fix */
    .grid:after {
        content: "";
        display: block;
        clear: both;
    }

    /* ---- .grid-item ---- */
    .grid-sizer,
    .grid-item {
        width: 33.333%;
        height: 250px;
        @include media-breakpoint-down(sm) {
            height: 350px;
        }
        display: flex;
        justify-content: center;
        overflow: hidden;
        background-color: #eee;
        float: left;
        border: 2px solid #fff;

        img {
            height: 250px;
            @include media-breakpoint-down(sm) {
                height: 350px;
            }

            max-width: none;
            width: auto;
            display: block;
        }
    }
}

//Carrousel Galery
.carousel-gallery {
    .carousel-inner {
        @media (max-width: 767px) {
            .carousel-item > div {
                display: none;
            }
            .carousel-item > div:first-child {
                display: block;
            }
        }

        .carousel-item {
            .card {
                background-color: #eee;
                border: 2px solid #fff;
                border-radius: 0px;
                
                .card-img {
                    overflow: hidden;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    border-radius: 0px;

                    a {
                        display: inline-block;

                        img {
                            height: 250px;
                            @include media-breakpoint-down(sm) {
                                height: 350px;
                            }

                            width: auto !important;
                            max-width: none !important;
                        }
                    }
                }
            }
        }

        .carousel-item.active,
        .carousel-item-next,
        .carousel-item-prev {
            display: flex;
        }

        /* medium and up screens */
        @media (min-width: 768px) {
            .carousel-item-end.active,
            .carousel-item-next {
                transform: translateX(25%);
            }

            .carousel-item-start.active,
            .carousel-item-prev {
                transform: translateX(-25%);
            }
        }

        .carousel-item-end,
        .carousel-item-start {
            transform: translateX(0);
        }
    }
}
