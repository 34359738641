// App
@import 'app';

.alert-box {
  position: fixed;
  z-index: 100;
  margin: auto;
  top: 65px;
  right: 0px;

  .alert-card {
    right: -500px;
    transition: .3s ease-in-out;
    display: none;
  }

  .alert-card.open {
    display: block;
  }
}
